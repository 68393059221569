<template>
  <CRow v-if="permissionsData.view_menus">
    <CCol col="12" v-if="showTable">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol lg="4" class="card-header-with-button">
              <CSelect
                label="Site"
                placeholder="-- Please Select --"
                :options="siteNameDropdown"
                :value.sync="selectedSite"
                @change="onChangeSite"
                :disabled="userHasSingleSite"
              >
              </CSelect>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody v-if="selectedSite != null">
          <CRow>
            <CCol lg="4" class="divider">
              <h4>Categories</h4>

              <CListGroup>
                <draggable
                  v-model="selectedSiteMenuAndItems"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  @change="reorderCategories"
                >
                  <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <CListGroupItem
                      v-for="item in selectedSiteMenuAndItems"
                      :key="item.category"
                      :value="item.category"
                      class="category"
                      @click="onMenuCategoryClick(item)"
                      v-bind:class="{
                        isDisabled:
                          (isEditing != item.category &&
                            item.category_enabled == 'false') ||
                          (isEditing == item.category &&
                            newEnabled == '' &&
                            item.category_enabled == 'false') ||
                          (isEditing == item.category &&
                            newEnabled != '' &&
                            newEnabled == 'false'),
                      }"
                    >
                      <CRow
                        v-bind:class="{ isEditing: isEditing == item.category }"
                      >
                        <CCol
                          style="flex: 0 0 65%;"
                          @click="onMenuCategoryClick(item)"
                        >
                          <CInput
                            v-if="isEditing === item.category"
                            v-model="newCategoryName"
                            v-focus
                          />
                          <div v-else>
                            {{ item.category }}
                          </div>
                        </CCol>
                        <CCol style="padding-left:0px;padding-right:0">
                          <div
                            v-if="isEditing == item.category"
                            @click="onCategoryEdit(item)"
                            class="hand-cursor"
                          >
                            <CIcon
                              name="cil-save"
                              class="float-right mt-2 ml-2"
                              v-if="
                                item.category != newCategoryName ||
                                  item.category_enabled != newEnabled
                              "
                            />
                          </div>
                        </CCol>
                        <CCol style="padding-left:0px;padding-right:0">
                          <div
                            v-if="isEditing != item.category"
                            @click="
                              newCategoryName = item.category;
                              newEnabled = '';
                              isEditing = item.category;
                            "
                            class="hand-cursor"
                          >
                            <CIcon
                              name="cil-pencil"
                              class="float-right mr-1 hide-until-hover"
                            />
                          </div>
                          <div
                            v-else
                            @click="onEyeClick(item)"
                            class="hand-cursor"
                          >
                            <CIcon
                              name="cil-eye"
                              class="float-right mt-2"
                              v-if="
                                (isEditing != item.category &&
                                  item.category_enabled != 'false') ||
                                  (isEditing == item.category &&
                                    newEnabled == '' &&
                                    item.category_enabled != 'false') ||
                                  (isEditing == item.category &&
                                    newEnabled != '' &&
                                    newEnabled != 'false')
                              "
                            />
                            <CIcon
                              name="cil-eye-slash"
                              class="float-right mt-2"
                              v-else
                            />
                          </div>
                        </CCol>
                        <CCol style="padding-left:0px;padding-right:0">
                          <div
                            v-if="isEditing === item.category"
                            @click="isEditing = ''"
                            class="hand-cursor"
                          >
                            <CIcon name="cil-X" class="float-right mt-2" />
                          </div>
                          <div
                            v-else
                            @click="onCategoryDelete(item)"
                            class="hand-cursor"
                          >
                            <CIcon
                              name="cil-trash"
                              class="float-right hide-until-hover"
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </CListGroupItem>
                  </transition-group>
                </draggable>
              </CListGroup>
              <CRow class="pt-3">
                <CCol lg="7">
                  <CInput placeholder="Category Name" v-model="newCategory" />
                </CCol>
                <CCol lg="5">
                  <CButton color="primary" @click="AddCategory">Add</CButton>
                </CCol>
              </CRow>
            </CCol>
            <CCol lg="8" v-if="selectedCategory != null">
              <CButton color="primary" class="float-right" @click="AddItems"
                >Add Item</CButton
              >
              <h3>{{ selectedCategory.category }}</h3>

              <CRow
                v-if="selectedCategory.items == 0"
                class="m-3 rounded p-5 bg-light text-center text-light h5"
              >
                <CCol
                  >Click "Add Item" to add some items to this category.</CCol
                >
              </CRow>
              <CRow v-else class="m-3">
                <CCol></CCol>
              </CRow>

              <draggable
                v-model="selectedCategory.items"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                @change="reorderMenuItems"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <!-- pageOfItems -->
                  <CRow
                    v-for="item in selectedCategory.items"
                    :key="item.item_number"
                    :value="item.item_number"
                    class="menu-item"
                    v-bind:class="{
                      'menu-item': true,
                      isDisabled: item.item_enabled == 'false',
                    }"
                    @click="onMenuItemClicked(item)"
                  >
                    <CCol lg="2">
                      <CImg
                        :src="item.image_url"
                        fluid
                        thumbnail
                        style="height:75px;"
                      />
                    </CCol>
                    <CCol lg="10">
                      <CRow>
                        <CCol>
                          <h5>{{ item.name }}</h5>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol lg="4"> Item #{{ item.item_number }} </CCol>
                        <CCol lg="4">
                          {{ item.category }}
                        </CCol>
                        <CCol lg="4">
                          {{ item.price_currency }}{{ item.price }}
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </transition-group>
              </draggable>
              <!-- <CPagination
                align="center"
                :activePage.sync="page"
                class="mt-3"
              ></CPagination> -->
              <!-- <CRow>
                <CCol style="text-align:center;top:10px;">
                  <jw-pagination
                    :items="selectedCategory.items"
                    @changePage="onChangePage"
                    :labels="customLabels"
                    :pageSize="pagePerScreen"
                  ></jw-pagination>
                </CCol>
              </CRow> -->
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import store from "../../store";
import constants from "../../data/Constants.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import Draggable from "vuedraggable";
import JwPagination from "jw-vue-pagination";

export default {
  name: "Users",
  data() {
    return {
      userHasSingleSite: false,
      permissionsData: {},
      pagePerScreen: 6,
      customLabels: {
        first: "<<",
        last: ">>",
        previous: "<",
        next: ">",
      },
      pageOfItems: [],
      newCategory: null,
      selectedCategory: null,
      selectedItem: null,
      selectedSiteMenuAndItems: [],
      selectedSite: null,
      siteNameDropdown: [],
      sitesData: [],
      credentials: null,
      showTable: false,
      activePage: 1,
      page: 1,
      drag: false,
      isEditing: "",
      newCategoryName: "",
      newEnabled: "",
    };
  },
  components: {
    Draggable,
    "jw-pagination": JwPagination,
  },
  mounted: function() {
    this.permissionsData = store.state.permissions;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  created() {
    if (this.credentials) {
      // have credentials
      console.log("in created(), have credentials");
      this.getSiteItems();
    } else {
      // no credentials
      console.log("in created(), getting credentials...");
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getCredentials().then(
        (data) => {
          this.credentials = data;
          this.getSiteItems();
        },
        (error) => {
          console.log(error);
          this.getSiteItems();
        }
      );
    }
    if (store.state.selectedSite != null) {
      this.selectedSite = store.state.selectedSite;
      this.getSiteItems(true);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "items",
        disabled: false,
        ghostClass: "ghost",
        forceFallback: true,
      };
    },
  },
  directives: {
    focus: {
      // directive definition
      inserted: function(el, binding) {
        console.log(el.querySelector("input"));
        if (el.querySelector("input") != null) {
          el.querySelector("input").focus();
        }
        if (el.querySelector("select") != null) {
          el.querySelector("select").focus();
        }
      },
    },
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    onEyeClick(item) {
      if (this.newEnabled == "") {
        if (item.category_enabled != "false") {
          this.newEnabled = "false";
        } else {
          this.newEnabled = "true";
        }
      } else {
        if (this.newEnabled != "false") {
          this.newEnabled = "false";
        } else {
          this.newEnabled = "true";
        }
      }
    },
    onCategoryEdit(item) {
      this.selectedSiteMenuAndItems.forEach((element) => {
        if (element.category_number == item.category_number) {
          element.category = this.newCategoryName;

          if (this.newEnabled != "") {
            element.category_enabled = this.newEnabled;
          }
        }
      });
      this.updateDB();
      this.isEditing = "";
    },
    reorderCategories() {
      //TODO Implement save
      this.updateDB();
    },
    reorderMenuItems() {
      this.updateDB();
    },
    onCategoryDelete(item) {
      this.$confirm(
        "Are you sure you want to delete this category and its items?"
      ).then(() => {
        this.selectedSiteMenuAndItems.forEach((element, index, object) => {
          if (element.category_number == item.category_number) {
            object.splice(index, 1);
          }
        });
        this.updateDB();
      });
    },
    AddItems() {
      this.$router.push({
        path: "/menus/" + 0,
        query: {
          selectedMenuItem: undefined,
          selectedCategory: this.selectedCategory,
          selectedSite: this.selectedSite,
        },
      });
    },
    addCategoryMenuItemsStructure() {
      var data = { category: "", items: [], category_number: "0" };
      return data;
    },
    AddCategory() {
      if (this.newCategory == null) {
        this.$alert(constants.CATEGORY, constants.INFORMATION);
        return;
      }
      var test = this.selectedSiteMenuAndItems;
      var newCatgeoryData = this.addCategoryMenuItemsStructure();
      newCatgeoryData.category = this.newCategory;
      if (
        this.selectedSiteMenuAndItems.length == undefined ||
        this.selectedSiteMenuAndItems.length == 0
      ) {
        this.selectedSiteMenuAndItems = [];
      }
      var count = this.selectedSiteMenuAndItems.length;
      count = count + 1;
      newCatgeoryData.category_number = String(count);
      this.selectedSiteMenuAndItems.push(newCatgeoryData);
      this.newCategory = null;
      this.updateDB();
    },
    updateDB() {
      var params = {
        TableName: constants.SITES_TABLE_NAME,
        Key: { site_id: this.selectedSite },

        UpdateExpression: `set #v1 = :site_menu_val`,
        ExpressionAttributeNames: {
          "#v1": "site_menu",
        },
        ExpressionAttributeValues: {
          ":site_menu_val": this.selectedSiteMenuAndItems,
        },

        ReturnValues: "ALL_NEW",
      };

      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.updateItem(params).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    onMenuCategoryClick(category) {
      category.items.forEach((element) => {
        if (
          element.image_url == undefined ||
          element.image_url == null ||
          element.image_url == ""
        ) {
          element.image_url = "img/photo_coming_soon.png";
        }
      });
      this.selectedCategory = category;
      //store.state.selectedCategory = category;
      store.commit("set", ["selectedCategory", category]);
    },
    defineProp(obj, key, value) {
      var config = {
        value: value,
        writable: true,
        enumerable: true,
        configurable: true,
      };
      Object.defineProperty(obj, key, config);
    },
    onChangeSite(isFromRedirect) {
      console.log("selectedSite " + this.selectedSite);
      this.selectedSiteMenuAndItems = [];
      this.selectedCategory = null;
      this.sitesData.forEach((element) => {
        if (element.site_id == this.selectedSite) {
          if (element.site_menu != undefined && element.site_menu != null) {
            this.selectedSiteMenuAndItems = element.site_menu;
          }
        }
      });
      var count = 0;
      this.selectedSiteMenuAndItems.forEach((element1) => {
        if (element1.category_number == undefined) {
          this.defineProp(element1, "category_number", null);
          count++;
          element1.category_number = String(count);
        }
      });
      //store.state.selectedSite = this.selectedSite;
      store.commit("set", ["selectedSite", this.selectedSite]);
      if (
        isFromRedirect != undefined &&
        isFromRedirect == true &&
        store.state.selectedCategory != null
      ) {
        this.selectedSiteMenuAndItems.forEach((element1) => {
          if (
            element1.category_number ==
            store.state.selectedCategory.category_number
          ) {
            element1.items.forEach((element, index, object) => {
              if (
                element.image_url == "" ||
                element.image_url == "img/upload_photo.png"
              ) {
                element1.items[index].image_url = "img/photo_coming_soon.png";
              }
            });
            //store.state.selectedCategory = element1;
            store.commit("set", ["selectedCategory", element1]);
            this.selectedCategory = element1;
          }
        });
      }
      var test = 0;
    },
    onMenuItemClicked(item) {
      this.selectedItem = item;

      this.$router.push({
        path: "/menus/" + item.item_number,
        query: {
          selectedMenuItem: item,
          selectedCategory: this.selectedCategory,
          selectedSite: this.selectedSite,
        },
      });
    },
    getSiteItems(isFromRedirect) {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.sitesData = [];
            that.siteNameDropdown = [];
            that.sitesData = data.Items;
            var role = store.state.currentUser.jogg_role;
            if (role == "admin") {
              that.sitesData.forEach((element) => {
                var temp = { label: "", value: "" };
                temp.label = element.site_name;
                temp.value = element.site_id;
                that.siteNameDropdown.push(temp);
              });
            } else {
              var currentUserSites = store.state.currentUser.sites;
              var splitSites = currentUserSites.split(",");

              that.sitesData.forEach((element) => {
                splitSites.forEach((element1) => {
                  if (element1 == element.site_id) {
                    var temp = { label: "", value: "" };
                    temp.label = element.site_name;
                    temp.value = element.site_id;
                    that.siteNameDropdown.push(temp);
                  }
                });
              });

              if (splitSites.length > 1) {
                this.userHasSingleSite = false;
              } else {
                this.userHasSingleSite = true;
                this.selectedSite = splitSites[0];
                this.onChangeSite();
              }
            }

            that.showTable = false;
            that.$nextTick().then(function() {
              that.showTable = true;
            });
            if (isFromRedirect != undefined && isFromRedirect == true) {
              this.onChangeSite(isFromRedirect);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
